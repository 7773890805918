import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import {IFooterData} from "../../../lib/types/common";
import {footerLinkComponentUI} from "./FooterComponentV2";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./FooterComponentV2Desktop.module.css"

const FooterComponentV2Desktop = (footerData: IFooterData) => {
    return (
        <>
            <div className={multipleClassName("col-md-7", "col-12", styles.footerTopLeftV2)}>
                <div className={styles.gamesSectionV2}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <p className={styles.allHeadingsV2}>
                                {footerData.gamesHeading}
                            </p>
                        </div>
                    </div>
                    {footerData.gamesSections && <div className={"row"}>{
                        footerData.gamesSections.map((item, index) => {
                            return (
                                <div className={multipleClassName("col-4", styles.linksCol)} key={"footerData_gamesSections_" + index}>
                                    <CustomLinkComponent
                                        href={item.path}
                                        mobStyle={{
                                            color: "#d8d8d8",
                                            textDecoration: "none"
                                        }}
                                        desktopStyle={{
                                            color: "#d8d8d8",
                                            textDecoration: "none"
                                        }}
                                    >
                                        {item.text}
                                    </CustomLinkComponent>
                                </div>
                            )
                        })
                    }</div>}
                </div>

                <div className={styles.blogSectionV2}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <p className={styles.allHeadingsV2}>
                                {footerData.blogHeading}
                            </p>
                        </div>
                    </div>
                    {footerData.blogSections && <div className={"row"}>{
                        footerData.blogSections.map((item, index) => {
                            return (
                                <div className={multipleClassName("col-4", styles.linksCol)} key={"footerData_blogSections_" + index}>
                                    <a href={item.path}>
                                        {item.text}
                                    </a>
                                </div>
                            )
                        })
                    }</div>}
                </div>

                <div className={styles.policiesSectionV2} id="footer_policies_section">{
                    footerData.policiesHeading &&
                    <>
                        <div className={"row"}>
                            <div className={"col"}>
                                <p className={styles.allHeadingsV2}>
                                    {footerData.policiesHeading}
                                </p>
                            </div>
                        </div>
                        <div className={"row"}>{
                            footerData.policiesSections.map((item, index) => {
                                return (
                                    <div className={multipleClassName("col-4", styles.linksCol)} key={"footerData_policiesSections_" + index}>
                                        <a href={item.path}>
                                            {item.text}
                                        </a>
                                    </div>
                                )
                            })
                        }</div>
                    </>
                }</div>

                <div className={styles.aboutUsSectionV2} id="footer_aboutus_section">
                    <div className={"row"}>
                        <div className={"col"}>
                            <p className={styles.allHeadingsV2}>
                                {footerData.footerHeading}
                            </p>
                        </div>
                    </div>
                    <div className={"row"}>{
                        footerData.footerSections.map((item, index) => {
                            let key = item.key;
                            return (
                                <div className={multipleClassName("col-4", styles.linksCol)} key={"footerData_footerSections_" + index}>
                                    {footerLinkComponentUI(key, index, item, footerData.downloadLink)}
                                </div>
                            )
                        })
                    }</div>
                </div>
            </div>
        </>
    )
}

export default FooterComponentV2Desktop