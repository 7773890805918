import HTMLReactParser from "html-react-parser";
import Analytics from "../../../lib/analytics/Analytics";
import {CareerPageClick} from "../../../lib/analytics/AnalyticsEvents";
import {IFooterComponentProps} from "../../../lib/types/components";
import {
    BUG_BOUNTY,
    CAREER_KEY,
    DOWNLOAD_KEY,
    GET_APP_AF_URL,
    GET_APP_URL
} from "../../../lib/utils/constants/GlobalConstants";
import {currentPageURL, handleFooterDownloadClick} from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import FooterComponentV2Desktop from "./FooterComponentV2Desktop";
import styles from "./FooterComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

export const footerLinkComponentUI = (key, index, item, downloadLink) => {

    return (
        <>
            {
                key === CAREER_KEY ?
                    <a href={item.path} onClick={() => {
                        Analytics.getInstance().logEvent(CareerPageClick);
                    }} className={styles.link}>
                        {item.text}
                    </a> :
                    key === DOWNLOAD_KEY ?
                        <CustomLinkComponent href={downloadLink}>
                                <span onClick={() => handleFooterDownloadClick()}>
                                    {item.text}
                                </span>
                        </CustomLinkComponent>
                        : key === BUG_BOUNTY ?
                            <a href={item.path} className={styles.link}>
                                {item.text}
                            </a>
                            :
                            <CustomLinkComponent href={item.path}>
                                {item.text}
                            </CustomLinkComponent>
            }
        </>
    )
}

const FooterComponentV2 = (props: IFooterComponentProps) => {

    const {
        footerData,
        downloadLink,
        membersData,
        withdrawalPartnerData,
        isUtmHotstar
    } = props;

    return (
        footerData ?
            <div className={multipleClassName("container-fluid", styles.footerContainerV2)}>
                <div className="container">
                    <div className={multipleClassName("row", styles.footerTop)}>
                        {<FooterComponentV2Desktop {...footerData} downloadLink={downloadLink}/>}

                        <div className={multipleClassName("col-md-5", "col-12", styles.footerTopRight)}>
                            <div className={styles.footerNavs}>
                                <h2 className={styles.headingMobile}>{footerData.mobileText}</h2>
                                <div className={styles.winzoFooterImg}>
                                    <CustomLinkComponent href="/">
                                        <CustomImageComponent
                                            layout={"fill"}
                                            objectFit={"contain"}
                                            src={footerData.winzoLogo}
                                            alt="winzo games logo"
                                        />
                                    </CustomLinkComponent>
                                </div>
                                <div className={styles.socialFooterContainer}>
                                    {
                                        footerData.socialMedia.map((item, index) => {
                                            let id = item.link.replace(/[^\w\s]/gi, '_')
                                            return (
                                                <a id={id} target="_blank" key={"footerData_socialMedia_" + index}
                                                   href={item.link}
                                                   rel={"noreferrer"} className={styles.socialImageContainer}>
                                                    <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        src={item.src}
                                                        alt={"social-media-image"}
                                                    />
                                                </a>
                                            )
                                        })
                                    }
                                </div>

                                {membersData && <div className={styles.membersDataContainer}>
                                    <h3 className={styles.membersDataHeading}>{membersData.heading}</h3>
                                    <div className={styles.memberContainer}>
                                        {
                                            membersData.list.map((item, index) => {
                                                return (
                                                    <div className={styles.memberImageContainer}
                                                         key={"membersData_list_" + index}>
                                                        <CustomImageComponent
                                                            key={index}
                                                            width={80}
                                                            height={55}
                                                            src={item.src}
                                                            alt={item.alt}
                                                            objectPosition={"left"}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>}
                                <div className={styles.ourPartnerContainer}>
                                    {withdrawalPartnerData &&
                                        <p className={styles.membersDataHeading}>
                                            {
                                                (currentPageURL === GET_APP_URL) && isUtmHotstar
                                                    ? footerData.ourPartnersText
                                                    : footerData.paymentPartnersText
                                            }
                                        </p>
                                    }
                                    {withdrawalPartnerData && withdrawalPartnerData.footerImg &&
                                        <div
                                            className={multipleClassName(styles.paymentContainer, styles.paymentContainerDesktop)}>
                                            <CustomImageComponent
                                                width={300}
                                                height={25}
                                                src={withdrawalPartnerData.footerImg.src}
                                                alt={withdrawalPartnerData.footerImg.alt}
                                            />
                                        </div>}
                                    {withdrawalPartnerData && withdrawalPartnerData.footerImgV2 &&
                                        <div
                                            className={multipleClassName(styles.paymentContainer, styles.paymentContainerMobile)}>
                                            <CustomImageComponent
                                                layout={"fill"}
                                                objectFit={"contain"}
                                                src={withdrawalPartnerData.footerImgV2.src}
                                                alt={withdrawalPartnerData.footerImgV2.alt}
                                            />
                                        </div>}
                                </div>
                                {withdrawalPartnerData && <hr className={styles.lineSeparator}></hr>}
                                <div className={styles.colMobile} id="footer_aboutus_section">
                                    <div className="row no-gutters">
                                        <p className={multipleClassName("col", styles.allHeadingsV2)}>
                                            {footerData.footerHeading}
                                        </p>
                                    </div>
                                    <div className={"row no-gutters"}>{
                                        footerData.footerSections.map((item, index) => {
                                            let key = item.key;
                                            return (
                                                <div className={multipleClassName("col-6", styles.linksCol)}
                                                     key={"footerData.footerSections_" + index}>
                                                    {footerLinkComponentUI(key, index, item, downloadLink)}
                                                </div>
                                            )
                                        })
                                    }</div>
                                </div>
                                {footerData.footerHeading && <hr className={styles.lineSeparator}></hr>}
                                {
                                    footerData.policiesHeading &&
                                    <div className={styles.colMobile} id="footer_policies_section">
                                        <div className="row no-gutters">
                                            <p className={multipleClassName("col", styles.allHeadingsV2)}>
                                                {footerData.policiesHeading}
                                            </p>
                                        </div>
                                        <div className={"row no-gutters"}>{
                                            footerData.policiesSections.map((item, index) => {
                                                return (
                                                    <div className={multipleClassName("col-6", styles.linksCol)}
                                                         key={"footerData.policiesSections_" + index}>
                                                        <a href={item.path} key={index}>
                                                            {item.text}
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }</div>
                                    </div>
                                }
                                <hr className={styles.lineSeparator}></hr>
                                <div className={styles.disclaimerContainer}>
                                    <h3 className={styles.disclaimerHeading}>{footerData.disclaimer.heading}</h3>
                                    <p className={styles.disclaimerText}>{footerData.disclaimer.text} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={styles.biggerLineSeparator}></hr>
                    <div className={multipleClassName("row", styles.footerBottom)}>
                        <div className={multipleClassName("col", styles.copyright)}>
                            <p>
                                    <span>
                                        © {new Date().getFullYear()}{footerData.rightsReservedText}{" "}
                                    </span>
                                {
                                    footerData.footerLinks.map((item, index) => {
                                        let id = item.path.replace(/[^\w\s]/gi, '_')
                                        return (
                                            <span id={id} key={"footerData.footerLinks_" + index}
                                                  className={styles.footerLinks}>
                                                    <CustomLinkComponent
                                                        href={item.path}>{item.text}</CustomLinkComponent> - {" "}
                                                </span>
                                        )
                                    })
                                }
                                {
                                    footerData.linkText && <span>
                                        {HTMLReactParser(footerData.linkText)}
                                      </span>
                                }
                            </p>
                            {
                                currentPageURL === GET_APP_AF_URL && (
                                    <p>{footerData.addressText}</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            :
            <></>
    )
}

export default FooterComponentV2;